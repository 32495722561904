<template>
  <vs-card>
    <div slot="header">
      <feather-icon icon="ChevronLeftIcon" svgClasses="h-4 w-4" class="mr-2 cursor-pointer" @click="$router.push('/admin/pages')"></feather-icon>
      <vs-label style="font-weight: bold">Update</vs-label>
    </div>
    <div style="padding-left: 20px;" v-if="settings.length >0 ">
<!--    <h4 class="mb-6 mt-2">Se Details</h4>-->
<!--    <vs-form>-->
      <div v-for="setting in settings" :key="setting._id">
      <vs-input :label="setting.name"  :name="setting.slug" type="number" :min="1" :max="100"  v-validate="'required|numeric|max_value:100'" class="col-sm-3 mb-6" v-model="setting.value" :data-vv-as="setting.slug"></vs-input>
        <span class="text-danger text-sm"  v-show="errors.has(setting.slug)">{{ errors.first(setting.slug) }}</span>

      </div>
<!--    </vs-form>-->
    <div slot="footer" class="mb-12 mt-12">
      <vs-row>
        <vs-button @click="savePageDetails" align="right">Save Details</vs-button>
      </vs-row>
    </div>
    </div>
  </vs-card>
</template>

<script>

  import { mapActions } from 'vuex';
  // import quillEditor from "../Editor";

  export default {
    name: 'pageViewEditDetail',
    data:()=>({
      settings:[],

    }),
    methods:{
      ...mapActions('general', ['fetchSetting','updateSetting']),
      getPageDetail() {
        let self = this;
        this.fetchSetting().then(res => {
          this.settings = res.data.data
        })
      },
      async savePageDetails(){
        let isValid = await this.$validator.validateAll()
        if(!isValid){
          return false
        }
        let id = this.$route.params.id
        let info = this.settings
        console.log(info);
        this.updateSetting(info).then(res => {
          this.$vs.notify({
            title: res.data.data.title,
            text: res.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })
        })
      }
    },
    created() {
      // let pageId = this.$route.params.id;
      this.getPageDetail();
    }
  }
</script>
<style>
  .ql-editor{
    min-height:200px;
  }
  .label {
    font-size: small;
    color: black;
  }
</style>
